
import Reader from 'containers/Reader';
import { useReducer } from "react";
import { useParams } from "react-router-dom";


const Preview = () => {
    const {id} = useParams();    
    const viewerRef = useReducer(null);
    return (
        <div>
        <Reader url={`https://boighor-content.s3.ap-southeast-1.amazonaws.com/media/books_preview/${id}.epub`}  userId={'userId'}  ref={viewerRef} bookcode={id}/> 
       
        </div>
    );
};

export default Preview;