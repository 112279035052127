// components
import ControlBtn from 'components/header/ControlBtn'
import Layout, { AutoLayout } from 'components/header/Layout'
import Logo from 'components/header/Logo'
import Wrapper from 'components/header/Wrapper'
import palette from 'lib/styles/palette'
import styled from 'styled-components'

const Header = ({
  onNavToggle, 
  onOptionToggle, 
  onLearningToggle,
  bookcode
}: Props) => {
  return (
    <Wrapper>
      <Layout>
        <AutoLayout>
          <Logo  bookcode={bookcode} />
          <div style={{paddingRight:'2rem' }}>
            {/* <ControlBtn message={<>Back</>} onClick={()=> window.open(`https://gpboimela.com/bookdetails/${bookcode}`,'_self')} /> */}
            <ControlBtn message={<>অধ্যায়</>} onClick={onNavToggle} />
            <ControlBtn message={<I className="material-icons">settings</I>} onClick={onOptionToggle} />
            {/* <ControlBtn message="Highlights" onClick={onLearningToggle} /> */}
           
          </div>
        </AutoLayout>
      </Layout>
    </Wrapper>
  );
}
const I = styled.i`
 &:focus, &:hover {
    color: ${palette.blue4};
  }
`
interface Props {
  onNavToggle: (value?: boolean) => void;
  onOptionToggle: (value?: boolean) => void;
  onLearningToggle: (value?: boolean) => void;
  bookcode: string
}

export default Header