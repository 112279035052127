import { mediaQuery } from 'lib/styles/media'
import palette from 'lib/styles/palette';
// lib
import * as styles from 'lib/styles/styles'
import styled from 'styled-components'

const Logo = ({bookcode}: Props) => {
	return (
		<div>
			
		<Wrapper href={`https://gpboimela.com/bookdetails/${bookcode}`} target="_self">
		 <i className="material-icons">arrow_back</i>
		<Img src="/images/logo.svg" alt="Logo" />
		</Wrapper>
		</div>
		
	);
}

const Wrapper = styled.a`
	display: flex;
	align-items: center;
	justify-content: center;
	outline: none;
	margin-left: 20px;
	background-color: rgba(0,0,0,0);
	transition: .2s ${styles.transition};
	border-radius: 8px;
	padding: 4px 8px;
	cursor: pointer;

	${mediaQuery(700)} {
		margin-left: 4px;
		padding: 1px 2px;
	}

	&:hover {
    background-color: ${palette.blue0}
  }

	
`;

const Img = styled.img`
	height: 44px;
	${mediaQuery(700)} {
		height: 34px;
	}
`;

interface Props{
	bookcode: string;
}
export default Logo