import { useRef } from 'react';

const Home = () => {
    // const  decrypt = CryptoJS.AES.decrypt('U2FsdGVkX18vNT026tZ7FzCDDzdRvZH28+Y8l6mQp3mhf25rHPpeEADAQNjPSuptHkoaBU+mjokBWhnJP6wkQqYm5WXkJmEHS75CHD6XpXNN+wvYcs5o4ZyqMqxeIdFo', 'eb$bdre@derb0!gh0r##');
    // const decryptStr = decrypt.toString(CryptoJS.enc.Utf8)
    // const decryptInfo = decryptStr.slice(1,-1).split('////')  // slice use for removeing quotation("") from first and last string.
    // const [bookcode, reqTime, reqIP, msisdn] = decryptInfo;
    // console.log('Print',decryptStr);

    const viewerRef = useRef(null);
    return (
        <div style={{height: "100vh", display:'flex',justifyContent:'center', alignItems:'center'}}>
            <h1>Welcome to GP Boimela !!!</h1>
        </div>
    );
};

export default Home;